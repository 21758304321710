/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      // Newsletter popup
      function setNewsletterCookie() {
        document.cookie = 'hide_newsletter=true; path=/';
      }

      $('.newsletter-popup .close-popup').click(function () {
        $('.newsletter-popup').removeClass("active");
        $('.newsletter-popup').fadeOut();
        setNewsletterCookie();
      });

      function addContact(email, firstName, lastName) {
        const data = {
          action: "dotdigital",
          email: email,
          firstName: firstName,
          lastName: lastName
        };

        $('.newsletter-popup .loading-icon-container, .footer-newsletter .loading-icon-container').fadeIn();
        $.ajax({
          type: "POST",
          url: ajaxurl,
          data: data
        }).done(function (responseJSON) {
          const response = JSON.parse(responseJSON);
          $('.newsletter-popup .loading-icon-container, .footer-newsletter .loading-icon-container').fadeOut();
          if (response.id) {
            setNewsletterCookie();
            $('.newsletter-popup #signup, .footer-newsletter #signup').hide();
            $('.newsletter-popup .success-message, .footer-newsletter .success-message').show();
            $('.newsletter-popup .error-message, .footer-newsletter .error-message').hide();
          } else {
            $('.newsletter-popup .success-message, .footer-newsletter .success-message').hide();
            $('.newsletter-popup .error-message, .footer-newsletter .error-message').show();
          }
        });
      }

      $('.newsletter-popup #signup, .footer-newsletter #signup').submit(function(e) {
        e.preventDefault();

        const form = $(this);
        const email = form.find('#email').val();
        const firstName = form.find('#FIRSTNAME').val();
        const lastName = form.find('#LASTNAME').val();

        addContact(email, firstName, lastName);
      });

      function displayPopup() {
        $('.newsletter-popup.active').fadeIn();
      }

      $(window).on('load', function () {
        displayPopup();
      });

      //Cookie Consent
      window.addEventListener("load", function(){
        window.cookieconsent.initialise({
          "palette": {
            "popup": {
              "background": "#f55961",
              "text": "#ffffff"
            },
            "button": {
              "background": "#f5bb28",
              "text": "#ffffff"
            }
          },
          "position": "bottom-left",
          "content": {
            "href": window.location.origin + "/privacy-policy",
          }
        });});

        $('.row').equalize({children: '.option'});
        $('.row').equalize({children: '#ways-to-donate .blocks'});
        $('.row').equalize({children: '.challenge-events #featured-event .campaign-block'});

        $('.home-carousel').owlCarousel({
          loop: true,
          autoplayTimeout: 8000,
          autoplaySpeed: 1000,
          autoplayHoverPause: true,
          margin: 0,
          nav: true,
          items: 1,
          autoplay: true,
          navText : ['',''],
        });

        $('.social-carousel').owlCarousel({
            loop: true, //Set AutoPlay to 3 seconds
            items : 4,
            margin: 30,
            autoplayTimeout: 8000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            responsiveClass:true,
            autoplay: true,
            responsive:{
              0:{
                  items:1,
                  nav:true
              },
              600:{
                  items:2,
                  nav:false
              },
              900:{
                  items:3,
                  nav:false
              },
              1200:{
                  items:4,
                  nav:true,
                  loop:false
              }
            }
          });

          $('#search-toggle').click(function(){
             $('#header-search').slideToggle();
          });

         $('.twitter-slider-small').owlCarousel({
            loop: true, //Set AutoPlay to 3 seconds
            items : 2,
            autoplayTimeout: 8000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            margin: 30,
            nav: true,
            autoplay: true,
            navText : ['',''],
          });

          $('.twitter-slider').owlCarousel({
            loop: true, //Set AutoPlay to 3 seconds
            items : 4,
            autoplayTimeout: 8000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            margin: 30,
            nav: true,
            autoplay: true,
            navText : ['',''],
          });

          /* ================================================
                  ANIMATIONS IN FOOTER
          ================================================ */

          new WOW().init();

          /* ================================================
                  STRIPE FORM
          ================================================ */


          /* ================================================
                  Patient expand trigger
          ================================================ */
          $('.expand-more').click(function(){
            $(this).hide();
            $('#diary').slideToggle();
          });
          /* ================================================
                    Add class to donate form panels
          ================================================ */
          $('.migla-panel').each(function(){
              var findClass = $(this).find('h2');
              var classToAdd = $(findClass).attr('class');
              $(this).addClass(classToAdd);
              //console.log(classToAdd);
          });
          /* ================================================
            Google image gallery for our people
          ================================================ */
          (function( $ ) {
            $.imagePreview = function( element ) {
              this.$element = $( element );
              this.init();
            };
            $.imagePreview.prototype = {
              init: function() {
                this.$triggers = this.$element.find( ".image-link" );
                this.$closeLinks = this.$element.find( ".image-details-close" );

                this.open();
                this.close();
              },
              _getContent: function( element ) {
                var $parent = element.parent(),
                  title = $parent.data( "title" ),
                  desc = $parent.data( "desc" ),
                  extra = $parent.data( "extra" ),
                  image_url = $parent.data( "img-url" ),
                  video = $parent.data( "video" ),
                  html = element.html();
                  return {
                    title: title,
                    desc: desc,
                    extra: extra,
                    image_url: image_url,
                    video: video,
                    html: html
                  };
              },
              open: function() {
                var self = this;
                self.$triggers.on( "click", function( e ) {
                  e.preventDefault();
                  var $a = $( this ),
                    content = self._getContent( $a ),
                    $li = $a.parents( "li" ),
                    $details = $( ".image-details", $li ),
                    $contentImage = $( ".image", $details ),
                    $detailsTitle = $( ".image-details-title", $details ),
                    $detailsText = $( ".image-details-text", $details );
                    $detailsExtra = $( ".extra-info", $details );
                    $detailsVideo = $( ".video", $details );


                    $contentImage.html( content.image_url );
                    $detailsTitle.text( content.title );
                    $detailsText.html( content.desc );
                    $detailsExtra.text( content.extra );
                    $detailsVideo.html( content.video );

                    self.$element.find( ".image-details" ).fadeOut( "fast" );
                    $details.fadeIn( "fast" );
                    $("html, body").animate({ scrollTop: 0 }, 600);
                });
              },
              close: function() {
                this.$closeLinks.on( "click", function( e ) {
                  e.preventDefault();
                  $( this ).parent().slideUp( "fast" );
                });
              }
            };
            $(function() {
              var preview = new $.imagePreview( "#image-wrapper" );
            });
          })( jQuery );
        /* ================================================
                Google image gallery for our people END
        ================================================ */
      },
      finalize: function() {

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Hospital Map
    'hospital_map': {
      init: function() {
        // JavaScript to be fired on the hospital map page
      },
      finalize: function() {
        // JavaScript to be fired on the hospital map page, after the init JS

        $('.single-floor-tab').click(function(e){
          e.preventDefault();
          var thisFloor = $(this).attr("href");
          $('.mapplic-layer').hide();
          $('.mapplic-map').find('[data-floor="' + thisFloor + '"]').show();
          var select = $('.mapplic-levels-select');
          select.val(thisFloor);
          var numberOfOptions = select.find('option').length;

          // Update control arrows
          $('.mapplic-levels-up').removeClass('mapplic-disabled');
          $('.mapplic-levels-down').removeClass('mapplic-disabled');
          if (select.prop('selectedIndex') === numberOfOptions - 1) {
            $('.mapplic-levels-down').addClass('mapplic-disabled');
          }
          if (select.prop('selectedIndex') === 0) {
            $('.mapplic-levels-up').addClass('mapplic-disabled');
          }
  
        });
      }
    },

    // donate form page
    'donate': {
      init: function() {
        // Stripe
        var stripe = Stripe('pk_live_xMEX7PolDzJFgbQdaHb73jCI');
        var elements = stripe.elements();

        // Custom styling
        var style = {
          base: {
            color: '#02408A',
            fontWeight: 500,
            fontFamily: 'Varela Round, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
              color: '#ddd',
            },

            ':-webkit-autofill': {
              color: '#e39f48',
            },
          },
          invalid: {
            color: '#F55961',

            '::placeholder': {
              color: '#FFCCA5',
            },
          },
        };

        // Create card number field
        var cardNumber = elements.create('cardNumber', {style: style});
        cardNumber.mount('#card-number');

        cardNumber.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-number-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
        });

        // Create card expiry field
        var cardExpiry = elements.create('cardExpiry', {style: style});
        cardExpiry.mount('#card-expiry');

        cardExpiry.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-expiry-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
        });

        // Create cvc field
        var cardSecurityCode = elements.create('cardCvc', {style: style});
        cardSecurityCode.mount('#card-security-code');

        cardSecurityCode.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-security-code-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
        });

        // Create a token or display an error when the form is submitted.
        var form = document.getElementById('stripe-form');
        form.addEventListener('submit', function(event) {
          event.preventDefault();

          stripe.createToken(cardNumber).then(function(result) {
          if (result.error) {
              // Inform the customer that there was an error.
              var errorElement = document.getElementById('card-number-errors');
              errorElement.textContent = result.error.message;
          } else {
              // Send the token to your server.
              $('#acf-token_id').val(result.token.id);
              $('#acf-transaction').val('Stripe');
              $('#acf-donation_status').val('Pending');
              $('#form_donation').submit();
          }
        });
        });

        // PayPal
        $('#paypal-button').click(function() {
          $('#acf-transaction').val('Paypal');
          $('#acf-donation_status').val('Pending');
          $('#form_donation').submit();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the donate page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_galleries': {
      init: function() {
          $(".fancybox").fancybox();
      }
    },
    // Tiny lives in funds raised.
    'tinylives': {
      init: function() {

        //Set currency on the amount to be added
        jQuery('#custom-lives-donation').blur(function(){
            jQuery(this).formatCurrency({region: 'en-GB'});
        });

        //Validation Live
        var validator = jQuery( ".tinylivesform" ).validate({
          rules: {
            amount: {
              required: 'required',
              number: true
            }
          },
          messages: {
            amount: {
              required: 'required',
              minlength: 'Please tell input a price.',
            }
          },
        });
      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
